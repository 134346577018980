class UnknownError extends Error {
  original: unknown;

  constructor(original: unknown) {
    super('Unknown error');
    this.name = 'UnknownError';
    this.original = original;
  }
}

// Error object以外のthrowされたモノをErrorでラップする
export const wrapUnknownError = (e: unknown): Error => {
  // for debug
  if (process.env.NODE_ENV === 'development') {
    console.error(e);
  }

  if (e instanceof Error) return e;
  return new UnknownError(e);
};
