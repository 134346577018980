import { UrlObject } from 'url';
import NextLink from 'next/link';
import {
  Button,
  ButtonProps,
  Link as MuiLink,
  makeStyles,
} from '@material-ui/core';
import { Launch as LaunchIcon } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  linkButton: {
    textTransform: 'none',
  },
}));

export type ExternalLinkButtonProps = {
  children: ButtonProps['children'];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  href: string | UrlObject;
};

export const ExternalLinkButton = ({
  href,
  children = '外部リンク先名',
  variant = 'text',
  size = 'medium',
}: ExternalLinkButtonProps): JSX.Element => {
  const classes = useStyles();

  return (
    <NextLink href={href} passHref>
      <MuiLink target="_blank" rel="noopener noreferrer" color="secondary">
        <Button
          variant={variant}
          color="secondary"
          size={size}
          endIcon={<LaunchIcon />}
          className={classes.linkButton}
        >
          {children}
        </Button>
      </MuiLink>
    </NextLink>
  );
};
