import { MouseEvent, ReactElement } from 'react';
import NextLink, { LinkProps } from 'next/link';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  Tooltip,
  makeStyles,
  createStyles,
} from '@material-ui/core';

export type ButtonProps = {
  id?: MuiButtonProps['id'];
  href?: LinkProps['href'];
  variant?: MuiButtonProps['variant'];
  color?: MuiButtonProps['color'];
  size?: MuiButtonProps['size'];
  startIcon?: MuiButtonProps['startIcon'];
  endIcon?: MuiButtonProps['endIcon'];
  children: MuiButtonProps['children'];
  disableElevation?: MuiButtonProps['disableElevation'];
  disabled?: MuiButtonProps['disabled'];
  disabledReason?: string;
  target?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

export const Button = ({
  id,
  href,
  children,
  variant = 'contained',
  size = 'medium',
  color = 'primary',
  startIcon,
  endIcon,
  onClick,
  disabled = false,
  disabledReason,
  disableElevation = true,
  className,
}: ButtonProps): JSX.Element => {
  return withTooltip(disabledReason)(
    withLink(href)(
      <MuiButton
        id={id}
        variant={variant}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        size={size}
        disableElevation={disableElevation}
        disabled={disabled}
        onClick={onClick}
        className={className}
      >
        {children}
      </MuiButton>,
    ),
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    tooltip: {
      fontSize: 14,
    },
  }),
);

const withTooltip = (disabledReason?: string) => {
  const WithToolTip = (component: ReactElement) => {
    const classes = useStyles();

    if (!disabledReason) return component;

    return (
      <Tooltip
        title={disabledReason}
        interactive
        arrow
        classes={{ tooltip: classes.tooltip }}
      >
        <div>{component}</div>
      </Tooltip>
    );
  };
  return WithToolTip;
};

const withLink = (href?: LinkProps['href']) => {
  const WithLink = (component: ReactElement) => {
    if (!href) return component;

    return (
      <NextLink href={href} passHref>
        {component}
      </NextLink>
    );
  };
  return WithLink;
};
